//Branding Customizations

//Logo Sizing
//Use 110 for horizontal logos and 130 for vertical;  
$custom-logoMaxHeight: 110px;

//Primary Text Color
//Body text side-wide
//Left to bootstrap default which is black

//Primary Branding Color
//Where this appears:
//1. Member Portal Banner (should be a dark color; text will be white)
//2. Form button color
//3. Border color for all containers, forms, callouts
//4. Background color for side nav in FHIR data section
$custom-main:#233E77;

//Secondary Branding Color
//Where this appears:
//1. Navigation Banners (top and bottom)
//2. Rollover color for form buttons
$custom-secondary: #2664A2;

//Main Nav - Active Text
//Should set to main color or black
$custom-activeTxt: #233E77;
//$custom-activeTxt: #000;

//Main Nav - Inactive Text
//Should set to main color or black or white (most common)
//$custom-inactiveTxt: #233E77;
//$custom-inactiveTxt: #000;
$custom-inactiveTxt: #fff;
